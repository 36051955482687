
import { useEffect, useRef, useState } from 'react'

export const VolFader = () => {
  const boxRef = useRef<HTMLDivElement>(null)
  const [val, setVal] = useState('0')
  const [h, setH] = useState(80)
  const setValue = (e) => {
    const v = e.target.value
    setVal(v)
  }

  useEffect(() => {
    const parent = boxRef.current?.parentElement || document.createElement('div')
    const onResize = () => {
      setH(boxRef.current?.offsetHeight || 40)
    }
    window.addEventListener('resize', onResize)
    parent.addEventListener('transitionend', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
      parent.removeEventListener('transitionend', onResize)
    }
  }, [])

  return (
    <div className="w-8 h-full relative " ref={boxRef}>
      <input
        className="range range-lg -rotate-90 origin-left absolute -bottom-4 left-4 touch-none"
        style={{ width: h + 'px' }}
        type="range"
        min="0"
        step="0.01"
        max="1"
        value={val}
        onChange={setValue}
      />
      <div
        className="absolute top-4 left-4 width-full justify-center flex"
      >{val}
      </div>
    </div>
  )
}
export const PanFader = () => {
  const [val, setVal] = useState('0')
  const setValue = (e) => {
    const v = e.target.value
    setVal(v)
  }
  const reset = () => setVal('0.5')

  return (
    <input
      className="fader fader-error touch-none [--range-shdw:rgba(0,0,0,0)]"
      type="range"
      min="0"
      step="0.01"
      max="1"
      value={val}
      onChange={setValue}
      // carefule on chrome, double click does NOT work when inspector is open !! (Sept. 2024)
      onDoubleClick={reset}
    />
  )
}
