import React from 'react'
import { createRoot } from 'react-dom/client'
import { Main } from './ui'
import './index.css'


const container = document.querySelector('#root')
const root = createRoot(container || document.createElement('div'))

root.render(<Main />)
