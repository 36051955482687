import { assertEvent, assign, forwardTo, setup, fromCallback, sendParent } from 'xstate'
import db, {
  Media,
  proxyUrl,
  getMediaUrl
} from './database'

export const dll = (uri: string, update: (cl: number, rl: number) => void): Promise<{ type: string | null, buffer: ArrayBuffer }> => fetch(uri)
  .then(response => {
    if (!response.ok || !(response.body && response.headers)) return Promise.reject(new Error('failed to download'))
    const cl = response.headers.get('Content-Length')
    const contentLength = cl ? parseInt(cl) : 0
    const type = response.headers.get('Content-Type')
    console.log('downloading', uri, type, contentLength)
    let receivedLength = 0
    update(contentLength, receivedLength)

    const reader = response.body.getReader()
    return {
      type,
      stream: new ReadableStream({
        start(controller) {
          const pump = (): Promise<void> => reader.read().then(({ done, value }) => {
            // When no more data needs to be consumed, close the stream
            if (done) {
              controller.close()
              return
            }
            receivedLength += value.length
            update(contentLength, receivedLength)
            // console.log(`Received ${value.length} bytes`, receivedLength)
            // Enqueue the next data chunk into our target stream
            controller.enqueue(value)
            return pump()
          })
          return pump()
        }
      })
    }
  })
  .then(async ({ type, stream }) => {
    const res = new Response(stream)
    return res.arrayBuffer()
      .then(buffer => ({ type, buffer }))
  })

const downloader = fromCallback(({ sendBack, receive }) => {
  receive((event: DownloadEvent) => {
    if (event?.type !== 'DOWNLOAD' || !event.media?.uri || event.media.uri.trim() === '') {
      return
    }
    const controller = new AbortController()
    sendBack({ type: 'SET_DL_MAP', uri: event.media.uri, controller })

    return db.buffers.get(event.media.uri)
      .then(m => m?.buffer
        ? null
        : getMediaUrl(controller.signal)(event.media)
          .then(({ src }) => dll(
            src,
            (contentLength, receivedLength) => sendBack({ type: 'UPDATE_DL_MAP', uri: event.media.uri, contentLength, receivedLength })
          )
            .catch(() => dll(
              proxyUrl(src),
              (contentLength, receivedLength) => sendBack({ type: 'UPDATE_DL_MAP', uri: event.media.uri, contentLength, receivedLength })
            ))
            .then(({ type, buffer }) => db.buffers.put(Object.assign({}, event.media, { type, buffer, createdOn: new Date() })))
            .then(() => db.buffers.getKeys().then(keys => sendBack({ type: 'ANNOUNCE_DL_KEYS', keys })))
          )
      )
      .catch(console.error)
      .finally(() => sendBack({ type: 'DOWNLOAD_REMOVE', uri: event.media.uri }))
  })
  return () => {
  }
})
export type DLContext = {
  downloads: Map<string, unknown>
}

type DownloadEvent = { type: 'DOWNLOAD', media: Media }
type DownloadRemoveEvent = { type: 'DOWNLOAD_REMOVE', uri: string }
type SetDlMap = { type: 'SET_DL_MAP', uri: string, controller: AbortController }
export type UpdateDlMap = { type: 'UPDATE_DL_MAP', uri: string, contentLength: number, receivedLength: number }
export type AnnounceDlKeys = { type: 'ANNOUNCE_DL_KEYS', keys: Array<string> } // when done downloading, announce all new files

export type DLEvents = DownloadEvent
  | AnnounceDlKeys
  | DownloadRemoveEvent
  | SetDlMap
  | UpdateDlMap

export const DownloadMachine = setup({
  types: {
    context: {} as DLContext,
    events: {} as DLEvents,
  },
  actions: {
    sendDl: forwardTo('downloader'),
    setDlMap: assign({
      downloads: ({ context, event }) => {
        assertEvent(event, "SET_DL_MAP")
        return new Map(
          context.downloads.set(event.uri, {
            uri: event.uri,
            controller: event.controller
          })
        )
      }
    }),
    updateDlMap: assign({
      downloads: ({ context, event }) => {
        assertEvent(event, 'UPDATE_DL_MAP')
        return new Map(
          context.downloads.set(event.uri, Object.assign({}, context.downloads.get(event.uri), {
            contentLength: event.contentLength,
            receivedLength: event.receivedLength
          }))
        )
      }
    }),
    removeDl: assign({
      downloads: ({ context, event }) => {
        assertEvent(event, "DOWNLOAD_REMOVE")
        context.downloads.delete(event.uri)
        return new Map(context.downloads)
      }
    }),
  },
  actors: {
    downloader
  }
}).createMachine({
  id: 'downloadMachine',
  context: () => ({
    downloads: new Map()
  }),
  invoke: {
    src: 'downloader',
    id: 'downloader'
  },
  on: {
    DOWNLOAD: { actions: 'sendDl' },
    SET_DL_MAP: { actions: 'setDlMap' },
    UPDATE_DL_MAP: { actions: 'updateDlMap' },
    DOWNLOAD_REMOVE: { actions: 'removeDl' },
    ANNOUNCE_DL_KEYS: { actions: sendParent(({ event }) => event) },
  },
})
