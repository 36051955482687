import { useContext, useEffect, useCallback, useRef, useState } from 'react'
import { useSelector } from '@xstate/react'
import { MainDial } from './speeddials'
import { FakeChat } from './chat'
import { Player } from './player'
import { Mic } from './mic'
import { GlobalStateProvider, GlobalStateContext } from './globalState'

export function Mezcal() {
  const { mainService, wsService, rtcService } = useContext(GlobalStateContext)
  const audioRef = useRef<HTMLAudioElement>(null)
  const [time, setTime] = useState(0)

  const needsResume = useSelector(mainService, (snap) => snap.context.needsResume)
  const tracks = useSelector(mainService, (snap) => snap.context.tracks)
  const tracksOrder = useSelector(mainService, (snap) => snap.context.tracksOrder)
  // const retryDuration = useSelector(wsService, (snap) => snap.context.retryDuration)
  // const feed = useSelector(wsService, (snap) => snap.context.feed)
  const display = useSelector(wsService, (snap) => snap?.context.display)
  const remoteStream = useSelector(rtcService, (snap: any) => snap?.context?.remoteStream)
  const connectionState = useSelector(rtcService, (snap: any) => snap?.context?.connectionState)
  const iceConnectionState = useSelector(rtcService, (snap: any) => snap?.context?.iceConnectionState)

  useEffect(() => {
    // because of echo cancellation, chrome requires that remote streams
    // be placed in an <audio /> element in the DOM
    // https://bugs.chromium.org/p/chromium/issues/detail?id=121673
    // see also: https://dev.to/focusedlabs/echo-cancellation-with-web-audio-api-and-chromium-1f8m
    // be sure to check back later - July 2023
    if (remoteStream && audioRef.current) {
      const p = audioRef.current || new Audio()
      const onTimeUpdate = () => p ? setTime(p.currentTime) : null
      p.addEventListener('timeupdate', onTimeUpdate)
      p.srcObject = remoteStream
      p.play().catch(console.error)
      return () => {
        p.removeEventListener('timeupdate', onTimeUpdate)
      }
    }
    return () => null
  }, [remoteStream])

  const joinRtc = useCallback(() => {
    mainService.send({ type: "RESUME" })
    return rtcService
      ? rtcService.send({ type: 'RTC.JOIN', display: 'august', room: 'mezcal' })
      : null
  }, [rtcService, mainService])

  return (
    <div className="w-screen h-screen bg-neutral p-1 md:p-1 flex flex-col gap-1">
      <div className="w-full carousel h-2/3 sm:h-4/5 lg:h-3/5 flex gap-1">
        {tracksOrder.map(tid => {
          const track = tracks.get(tid)
          return track?.kind === 'mic'
            ? (<Mic key={tid} />)
            : (<Player key={tid} ></Player>)
        }
        )}
      </div>
      <div className="h-1/3 sm:h-1/5 lg:h-2/5 flex flex-col sm:flex-row gap-1 landscape:flex-row-reverse">
        <div className="basis-1/3 sm:basis-1/2 flex gap-1 lg:flex-row-reverse">
          <audio controls ref={audioRef} className="hidden" muted={true} />
          <div className="flex gap-4 flex-grow bg-base-300 rounded ">
            {rtcService
              ? (<button onClick={joinRtc} className='btn btn-primary'> join</button>)
              : null
            }

            <div className="text-xs">
              <div className="text-center text-grey-500">display: {display}</div>
              <div className="text-center text-grey-500">connection: {connectionState} </div>
              <div className="text-center text-grey-500">ice: {iceConnectionState}</div>
              <div className="text-center text-grey-500">time: {time}</div>
            </div>
          </div>
          <div className="flex-shrink bg-base-300 rounded flex flex-col place-items-center justify-center">
            <MainDial />
          </div>
        </div>
        <div className="bg-base-200 basis-2/3 sm:basis-1/2 overflow-auto px-4 rounded ">
          <div className="w-full flex flex-col ">
            <FakeChat />
          </div>
        </div>
      </div>

    </div >
  )
}
export const Main = () => (
  <GlobalStateProvider><Mezcal /></GlobalStateProvider>
)

