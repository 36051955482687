import { FC, ChangeEventHandler } from 'react'
import { Headset, Broadcast, IconProps } from './icons'

export type ToggleIconProps = {
  checked?: boolean
  className?: string
  Icon: FC<IconProps>
  onChange?: ChangeEventHandler<HTMLInputElement>
}

type ToggleProps = Omit<ToggleIconProps, 'Icon'>

export const ToggleIcon: FC<ToggleIconProps> = ({
  checked = false,
  className = '',
  onChange = () => null,
  Icon = Headset
}) =>
(<label className={"swap w-8 h-8 grid place-content-center active:scale-75 " + className}>
  <input type="checkbox" onChange={onChange} checked={checked} />
  <Icon on={true} className="swap-off h-6 w-6 " />
  <Icon on={false} className="swap-on h-6 w-6 text-error" />
</label>
)

export const ToggleHeadset: FC<ToggleProps> = ({
  checked = false,
  className = '',
  onChange = () => null,
}) => (<ToggleIcon Icon={Headset} checked={checked} onChange={onChange} className={className} />)

export const ToggleBroadcast: FC<ToggleProps> = ({
  checked = false,
  className = '',
  onChange = () => null,
}) => (<ToggleIcon Icon={Broadcast} checked={checked} onChange={onChange} className={className} />)

