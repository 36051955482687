import { setup, assign, ActorRefFrom, sendParent, assertEvent } from 'xstate'
import { BaseTrack } from './types'
import {
  NodesContext,
  NodesEvent,
  vol,
  pan,
  eqHi,
  eqMd,
  eqLo,
  master,
  broadcast,
  createNodes,
  connectToNodes,
  disconnectNodes,
  fadeOut,
  connectNodesToOut
} from './nodes'

export type OscContext = BaseTrack & NodesContext & {
  freq: number
  osc: OscillatorNode
}

export type OscEvents = NodesEvent
  | { type: 'SET_FREQ', freq: number }
  | { type: 'REMOVE_TRACK', id: string }
  | { type: 'DONE' }

export const oscMachine =
  setup({
    types: {
      input: {} as OscContext,
      context: {} as OscContext,
      events: {} as OscEvents
    },
    actions: {
      fadeOut,
      disconnect: ({ context }) => {
        console.log('disconnect')
        context.nodes.vol.gain.setValueAtTime(context.nodes.vol.gain.value, context.ctx.currentTime)
        context.osc.stop()
        context.osc.disconnect()
        disconnectNodes(context.nodes)
      },
      setFreq: assign({
        freq: ({ context, event }) => {
          assertEvent(event, 'SET_FREQ')
          context.osc.frequency.setValueAtTime(context.osc.frequency.value, context.ctx.currentTime)
          context.osc.frequency.linearRampToValueAtTime(event.freq, context.ctx.currentTime + 0.2)
          return event.freq
        }
      }),
      sendTrackInfo: sendParent(({ context }) => ({
        type: 'SEND_TRACK_INFO',
        id: context.id,
        track: {
          id: context.id,
          kind: context.kind,
          vol: context.vol,
          playing: context.vol > 0.002
        }
      }))
    }
  }).createMachine({
    id: 'OscMachine',
    context: ({ input }) => input as OscContext,
    initial: 'running',
    states: {
      running: {
        on: {
          DONE: {
            target: 'kill'
          }
        }
      },
      kill: {
        entry: ['fadeOut'],
        exit: [
          'disconnect',
          sendParent(({ context }) => ({
            type: 'REMOVE_TRACK',
            id: context.id
          }))
        ],
        after: {
          120: {
            target: 'done'
          }
        }
      },
      done: {
        type: 'final'
      }
    },
    on: {
      SET_FREQ: { actions: ['setFreq', 'sendTrackInfo'] },
      SET_VOL: { actions: [assign({ vol }), 'sendTrackInfo'] },
      SET_PAN: { actions: assign({ pan }) },
      SET_EQHI: { actions: assign({ eqHi }) },
      SET_EQMD: { actions: assign({ eqMd }) },
      SET_EQLO: { actions: assign({ eqLo }) },
      TOGGLE_M: { actions: assign({ master }) },
      TOGGLE_B: { actions: assign({ broadcast }) }
    }
  })

export type OscService = ActorRefFrom<typeof oscMachine>
export const createOscContext = (id: string, ctx: AudioContext, masterGain: GainNode, broadcastGain: GainNode, freq: number): OscContext => {
  const { nodes, values } = createNodes(ctx, true, true)
  const osc = ctx.createOscillator()
  connectToNodes(osc, nodes)
  connectNodesToOut(nodes, masterGain, broadcastGain)
  osc.frequency.setValueAtTime(freq, ctx.currentTime)
  osc.start(0)
  return {
    id,
    kind: 'webaudio',
    osc,
    ctx,
    freq,
    nodes,
    ...values
  }
}
