import { FC, useRef, useState } from 'react'
import { VolFader } from './faders'
import { Radial } from './radial'
import { ToggleHeadset, ToggleBroadcast } from './buttons'
import { DragHandle, ChevronLeft, ChevronRight } from './icons'

export const Eq: FC<{ label: string }> = ({
  label
}) => {
  const [value, setValue] = useState(0)
  return (
    <Radial value={value} onChange={setValue} min={-10} max={10} resetValue={0} label={label} />
  )
}
export const Pan = () => {
  const [value, setValue] = useState(0)
  return (
    <div className="w-8 h-14">
      <Radial value={value} onChange={setValue} min={-1} max={1} resetValue={0} label={'pan'} />
    </div>
  )
}

type TopBarProps = {
  big: boolean
  head: boolean
  setHead: (b: boolean) => void
  broad: boolean
  setBroad: (b: boolean) => void
}
const TopBar: FC<TopBarProps> = ({
  big = true,
  head = true,
  setHead,
  broad = true,
  setBroad
}) => big
    ? (
      <>
        <ToggleHeadset checked={head} onChange={(e) => setHead(e.target.checked)} />
        <ToggleBroadcast checked={broad} onChange={(e) => setBroad(e.target.checked)} />
        <div className='flex-grow' />
        <button className="h-8" ><ChevronLeft size='36px' /></button>
      </>
    )
    : (
      <>
        <ToggleHeadset checked={head} onChange={(e) => setHead(e.target.checked)} />
        <ToggleBroadcast checked={broad} onChange={(e) => setBroad(e.target.checked)} />
        <div className='flex-grow' />
        <button className="h-8" ><ChevronRight size='36px' /></button>
      </>

    )

type MicBodyProps = {
  big: boolean
}
const MicBody: FC<MicBodyProps> = ({
  big = false,
}) => {
  return big
    ? (
      <>
        <div key="title" className="flex-none text-xs text-nowrap w-full mx-1 overflow-hidden">this is the title of a track, could be long</div>
        <div className='h-10 bg-base-300 flex-grow flex flex-row gap-4 rounded m-1 lg:m-2 p-1 lg:p-2'>
          <VolFader />
          <div className='flex flex-col gap-4 w-full h-full'>
            <div className='flex gap-2 h-12'>
              <div className="flex-grow" />
              <div className='flex gap-2'>
                <Pan />
              </div>
              <div className="flex-grow" />
              <Eq label='lo' />
              <Eq label='md' />
              <Eq label='hi' />
              <div className='w-2' />
            </div>
          </div>
        </div>
      </>
    )
    : (
      <>
        <div key="title" className="flex-none text-xs text-nowrap w-full mx-1 overflow-hidden">this is the title of a track, could be long</div>
        <div className='h-10 bg-base-300 flex-grow flex flex-row gap-1 rounded m-1 lg:m-2 p-1 lg:p-2 relative'>
          <VolFader />
          <div className='flex-shrink flex flex-col gap-4 w-full h-full place-items-center'>
            <Pan />
            <Eq label='lo' />
            <Eq label='md' />
            <Eq label='hi' />
          </div>
          <button className="absolute -right-1 bottom-0 " ><DragHandle size='32px' /></button>
        </div>
      </>

    )
}

export const Mic = () => {
  const [big, setBig] = useState(false)
  const [head, setHead] = useState(false)
  const [broad, setBroad] = useState(false)
  const modalRef = useRef<HTMLDialogElement>(null)
  const onSearchModal = (o: boolean) => o
    ? modalRef.current?.showModal()
    : modalRef.current?.close()
  const onDoubleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setBig(b => !b)
  }
  return (
    < div data-theme={'valentine'} className={
      ' bg-base-100 carousel-item carousel-center flex flex-col flex-none rounded select-none ' +
      ' transition-all ease-in-out duration-300 ' +
      (big ? "w-full sm:w-1/2 xl:w-1/3 h-full " : "w-36 h-full")
    }
      onDoubleClick={onDoubleClick}
    >
      <div className='w-full h-8 flex flex-row gap-1'>
        <TopBar key="topbar" big={big} head={head} setHead={setHead} broad={broad} setBroad={setBroad} />
      </div>
      <MicBody key="body" big={big} />
      <dialog ref={modalRef} className="modal w-screen h-screen" >
        <div className="modal-box w-11/12 max-w-5xl h-full bg-base flex flex-col">
          <form method="dialog" className="bg-red-100 relative">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          </form>
          <div className='h-full w-full'>
            <h3 className="font-bold text-lg">Search</h3>
            ya</div>
        </div>
      </dialog>
    </div >
  )
}

