export enum ScaleType {
  Linear,
  Log,
  Db50,
  Db60,
  Db70,
  Speed
}

const linear = (min: number, max: number, val: number) =>
  val * (max - min) + min
// for some background on volume scaling:
// https://www.dr-lex.be/info-stuff/volumecontrols.html
const db50 = (val: number) => 3.1623e-3 * Math.exp(val * 5.757) // approx x^3
const db60 = (val: number) => 1e-3 * Math.exp(val * 6.908) // approx x^4
const db70 = (val: number) => 3.1623e-4 * Math.exp(val * 8.059) // approx x^5
const pow4 = (val: number) => Math.pow(val, 4)

const scaleSpeed = (val: number): number => {
  const input = val < 0
    ? 0
    : val > 1
      ? 1
      : val
  // Define constants for the ranges
  const inputMiddle = 0.5
  const outputMiddle = 1
  const outputMin = 0.25
  const outputMax = 4

  if (input < inputMiddle) {
    // Scale the first half (0 to 0.5) linearly from 0.25 to 1
    return outputMin + (outputMiddle - outputMin) * (input / inputMiddle)
  } else {
    // Scale the second half (0.5 to 1) linearly from 1 to 4
    return outputMiddle + (outputMax - outputMiddle) * ((input - inputMiddle) / (1 - inputMiddle))
  }
}


// Example usage
const linearInv = (min: number, max: number, scaledVal: number) =>
  (scaledVal - min) / (max - min)
const db70Inv = (scaledVal: number) =>
  scaledVal === 0
    ? 0
    : Math.log(scaledVal / 3.1623e-4) / Math.log(Math.exp(1)) / 8.059
const db60Inv = (scaledVal: number) =>
  scaledVal === 0
    ? 0
    : Math.log(scaledVal / 1e-3) / Math.log(Math.exp(1)) / 6.908
const db50Inv = (scaledVal: number) =>
  scaledVal === 0
    ? 0
    : Math.log(scaledVal / 3.1623e-3) / Math.log(Math.exp(1)) / 5.757

const pow4Inv = (scaledVal: number) => Math.pow(scaledVal, 0.25)

const scaleSpeedInv = (val: number): number => {
  // Ensure output is within the expected range
  const output = val < 0.25
    ? 0.25
    : val > 4
      ? 4
      : val
  // Define constants for the ranges
  const inputMiddle = 0.5
  const outputMiddle = 1
  const outputMin = 0.25
  const outputMax = 4

  if (output < outputMiddle) {
    // Inverse scaling for the first half (0.25 to 1)
    return (output - outputMin) / (outputMiddle - outputMin) * inputMiddle;
  } else {
    // Inverse scaling for the second half (1 to 4)
    return inputMiddle + (output - outputMiddle) / (outputMax - outputMiddle) * (1 - inputMiddle);
  }
}

export const scaleIt = (
  min: number,
  max: number,
  scale: ScaleType,
  val: number
) =>
  scale === ScaleType.Log
    ? linear(min, max, pow4(val))
    : scale === ScaleType.Db50
      ? linear(min, max, db50(val))
      : scale === ScaleType.Db60
        ? linear(min, max, db60(val))
        : scale === ScaleType.Db70
          ? linear(min, max, db70(val))
          : scale === ScaleType.Speed
            ? scaleSpeed(val)
            : linear(min, max, val)

export const scaleItInv = (
  min: number,
  max: number,
  scale: ScaleType,
  scaledVal: number
) =>
  scale === ScaleType.Log
    ? pow4Inv(linearInv(min, max, scaledVal))
    : scale === ScaleType.Db50
      ? db50Inv(linearInv(min, max, scaledVal))
      : scale === ScaleType.Db60
        ? db60Inv(linearInv(min, max, scaledVal))
        : scale === ScaleType.Db70
          ? db70Inv(linearInv(min, max, scaledVal))
          : scale === ScaleType.Speed
            ? scaleSpeedInv(scaledVal)
            : linearInv(min, max, scaledVal)

