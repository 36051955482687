import { FC } from 'react'

type ChatLineProps = {
  text: string
  start: boolean
}
export const ChatLine: FC<ChatLineProps> = ({
  text,
  start
}) => {
  return (
    <div className={"chat " + start ? "chat-start" : "chat-end"}>
      <div className="chat-header flex gap-2">
        <span className="font-bold text-xs">Obi-Wan Kenobi</span>
        <time className="text-xs opacity-50">12:45</time>
      </div>
      <div className="text-sm">{text}</div>
    </div >
  )
}

export const FakeChat = () => {
  const chats = [
    'hello, how is it going?',
    "todo bien, y que tal tu?",
    " no manchas, habla ingles.",
    "Just keep the chat going, there is  a lot to discuss"
  ]
  return chats.map((c, idx) => <ChatLine key={idx} text={c} start={idx !== 2} />)
}
